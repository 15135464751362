import React, {useContext, useCallback, useEffect, useRef, useState} from 'react';
import {CameratoggleContext} from "../../context/cameratoggle-context";
import './boothDetail.css';
import {useLocation, useNavigate,useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import Tooltip from "bootstrap/js/src/tooltip";
import {toast} from "react-toastify";
import {ACID, ACNAME, AUTH_STATUS, AUTH_TOKEN, BOOTHNAME, STATENAME} from "../SharingConstants";
import Loader from "react-js-loader";
import * as Constant from "../SharingConstants";
import {Camera} from "react-camera-pro";
import Select from "react-select";
import { useTranslation } from 'react-i18next';
import useGeolocation from "../useGeolocation/useGeolocaton";
import geolocationimg from '../../../../public/images/geolocationimg.png';
import imageCompression from 'browser-image-compression';
import RemoveIcon from '../../../../public/images/remove-icon.svg';
import { storage } from '../firebaseConfig'
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import api from "../api";
import { apiBaseUrl } from "../apiBaseUrl";
import {createFilterOptions, TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import {ClassNames} from "@emotion/react";

let formResult = {};
export default function BoothDetail() {
  const initialState = {
    id: localStorage.getItem('satateId'),
    name: localStorage.getItem('satateName')
  };
  // const { event_id, eventCount,create } = useParams();
  const url = new URL(window.location.href);
  const event_id = url.searchParams.get('event_id');
  const eventCount = url.searchParams.get('event_count');
  const create = url.searchParams.get('create');

  const eventCountAsNumber = parseInt(eventCount, 10);
  const navigate = useNavigate();
  const [loaderEnable, setloaderEnable] = useState(false);
  const {handleSubmit} = useForm();
  const [boothId, setBoothId] = useState();
  const [skId, setSkId] = useState('');
  const [boothError, setBoothError] = useState();
  const [skError, setSkError] = useState();
  const [boothName, setBoothName] = useState('');
  const [skName, setSkName] = useState('');
  // const [skLists, setSkLists] = useState([]);
  const [skBooths, setSkBooths] = useState([]);
  const [acBooths, setAcBooths] = useState([]);
  const [otherBoothInAc, setOtherBoothInAc] = useState([]);
  const [totalAttendees, setTotalAttendees] = useState('');
  const [totalAttendeesError, setTotalAttendeesError] = useState('');
  const [address, setAddress] = useState('');
  const [description, setDescription] = useState('');
  const [fileOne, setFileOne] = useState('');
  const [fileOneError, setFileOneError] = useState('');
  const [fileTwo, setFileTwo] = useState('');
  const [eventId, setEventId] = useState('');
  const [eventDetailId, setEventDetailId] = useState('');
  const [fileOnePath, setFileOnePath] = useState('');
  const [canCreate, setCanCreate] = useState('');
  const [fileTwoPath, setFileTwoPath] = useState('');
  const location = useLocation();
  const data = location.state
  const camera = useRef(null);
  const [openCamera, setOpenCamera] = useState(false);
  const { t } = useTranslation();
  const geolocation = useGeolocation();
  const [geoLocationErrorFlag,setgeoLocationErrorFlag] = useState(false);
  const [latitude,setlatitude] = useState('');
  const [longitude,setlongitude] = useState('');
  const [imgUrlOne, setImgUrlOne] = useState('');
  const [imgUrlTwo, setImgUrlTwo] = useState('');
  const [stateList, setStateList] = useState([]);
  const [state, setState] = useState(initialState);
  const [vidhanSabhaList, setVidhanSabhaList] = useState([]);
  const [vidhanSabha, setVidhanSabha] = useState("");
  const [boothList, setBoothList] = useState([]);
  const [booth, setbooth] = useState("");
  let boothOptions = [];
  const { setTogglefooter } = useContext(CameratoggleContext);

  const onOpenCameraClickHandler = () => {
    setTogglefooter(true);
    setOpenCamera(true);
  }

  const handleOnCaptureClick = () => {
    getImage(camera);
    setTogglefooter(false)
  }

  useEffect(() => {
    let authStatus = JSON.parse(localStorage.getItem(AUTH_STATUS));
    {
      !authStatus && navigate('/', {replace: false})
    }
  })
  if (skBooths.length || otherBoothInAc.length) {
     boothOptions = [
      {
        label: "Booth in Shakti kendra",
        options: skBooths
      },
      {
        label: "Other Booth in Ac",
        options: otherBoothInAc
      }
    ];
  } else {
     boothOptions = [{
      label: "Booths",
      options: acBooths
    }]
  }

  useEffect(() => {
      getStates();
  }, []);

  useEffect(() => {
    let authStatus = JSON.parse(localStorage.getItem(AUTH_STATUS));
    {
      // !skLists.length && authStatus && getSk('');
      !acBooths.length && authStatus;
    }
  }, [])


  useEffect(() => {
    let authStatus = JSON.parse(localStorage.getItem(AUTH_STATUS));
    if (authStatus && event_id !== null && create) {
      setEventId(event_id);
      setCanCreate(true);
    } else {
      if (data !== null && data.formResult.path === "/review") {
        setBoothId(data.formResult.booth_id);
        setSkId(data.formResult.sk_id);
        setBoothName(data.formResult.booth_name);
        setSkName(parseInt(data.formResult.sk_id) > 0 ? data.formResult.sk_name : '');
        setTotalAttendees(data.formResult.total_attendees);
        setAddress(data.formResult.address);
        setDescription(data.formResult.description);
        setFileOne(data.formResult.file_one);
        setImgUrlOne(data.formResult.file_one);
        setFileOnePath(data.formResult.photo1);
        setFileTwo(data.formResult.file_two);
        setImgUrlTwo(data.formResult.file_two);
        setFileTwoPath(data.formResult.photo2);
        setEventId(data.formResult.event_id);
        setCanCreate(data.formResult.can_submit);
        setEventDetailId(data.formResult.event_detail_id);
        setlatitude(data.formResult.latitude);
        setlongitude(data.formResult.longitude);
        setbooth({ id: data.formResult.booth_id, name: data.formResult.booth_name })
        setVidhanSabha(data.formResult.VidhanSabha)
        setState(data.formResult.country_state)
        getBooth(data.formResult.VidhanSabha.id)
      }
    }
  }, []);


  const getStates = async () => {     //This function helps to fetch country states when called
    try {
      console.log('Base URL:', process.env.BASE_URL);
      let states = await fetch(apiBaseUrl + `/api/event_tracker/get_country_state?event_id=${event_id}`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Accept": 'application/json',
          "Authorization": localStorage.getItem(AUTH_TOKEN)
        },
      }); // Make API call using the api instance
      const res = await states.json();
      if (res.status === false) {
        toast(res.message);
        return;
      }
      if (res.data.length === 1) {
        setState(res.data[0]);
        getVidhanSabha(res.data[0].id);
      } else {
        setStateList(res?.data);
        getVidhanSabha(localStorage.getItem('satateId'));
      }
    } catch (error) {
      console.error('API Call Error:', error);
    }
  };


  const getVidhanSabha = async (state_id) => {       //This function helps to fetch Vidhan Sabha when called
    try {
      let country_state_id = ((state_id === undefined) ? "" : state_id);
      let acs = await fetch(apiBaseUrl + "/api/event_tracker/get_acs?country_state_id="+ country_state_id, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Accept": 'application/json',
          "Authorization": localStorage.getItem(AUTH_TOKEN)
        },
      }); // Make API call using the api instance
      const res = await acs.json();
      if (res.status === false) {
        toast(res.message);
        return;
      }
      setVidhanSabhaList(res.data);
    } catch (error) {
      console.error('API Call Error:', error);
    }
  };


  const getBooths = async (skBoothList = [], ac_id = '') => {
    try {
      let body = {ac_id: ac_id}
      const booths = await fetch(apiBaseUrl +'/api/event_tracker/get_booth', body, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Accept": 'application/json',
          "Authorization": localStorage.getItem(AUTH_TOKEN)
        }
      }); // Make API call using the api instance
      console.log('API Call Response:', booths.data);
      const res = await booths.json();
      setAcBooths(res.data);
      handleBoothList(res.data, skBoothList);
    } catch (error) {
      console.error('API Call Error:', error);
    }
  };


  const getBooth = async (ac_id) => {     //This function helps to fetch Booths when called
    try {
      const booth = await fetch(apiBaseUrl +`/api/event_tracker/get_booth?ac_id=${ac_id}`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Accept": 'application/json',
          "Authorization": localStorage.getItem(AUTH_TOKEN)
        },
      }); // Make API call using the api instance
      const res = await booth.json();
      if (res.status === false) {
        toast(res.message);
        return;
      }
      setBoothList(res.data);
    } catch (error) {
      console.error('API Call Error:', error);
    }
  };



  const getSkBooth = async (sk_id) => {
    if (parseInt(sk_id) > 0) {
      setloaderEnable(true);
      try {
        let body = {sk_id: sk_id}
        const sk_booth = await api.post('api/event_tracker/get_sk_booth', body, {
          headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json',
            "Authorization": localStorage.getItem(AUTH_TOKEN)
          }
        }); // Make API call using the api instance
        console.log('API Call Response:', sk_booth.data);
        const res = await sk_booth.data;
        setSkBooths(res.data)
        getBooths(res.data);
      } catch (error) {
        console.error('API Call Error:', error);
      }
      setloaderEnable(false);
    }
  };


  const onSubmit = () => {
    if (!boothId || !totalAttendees) {
      toast.dismiss()
      toast(t('Please fill all * mandatory fields'))
      return
    } else if(!vidhanSabha|| !state){
      toast.dismiss()
      toast(t('Please fill all * mandatory fields'))
      return
    } else if (!fileOnePath && !fileTwoPath) {
      toast.dismiss()
      toast(t('Upload minimum one image'))
      return
    }
    const formData = new FormData();
    formData.append('booth_id', boothId);
    formData.append('booth_name', boothName);
    formData.append('total_attendees', totalAttendees);
    formData.append('address', address);
    formData.append('description', description);
    formData.append('file_one', imgUrlOne);
    formData.append('file_two', imgUrlTwo);
    formData.append('event_id', event_id);
    formData.append('can_create', canCreate);
    formData.append('sk_id', skId);
    formData.append('sk_name', skName);
    formResult = {
      'sk_id': formData.get('sk_id'),
      'sk_name': formData.get('sk_name'),
      'booth_id': formData.get('booth_id'),
      'booth_name': formData.get('booth_name'),
      'total_attendees': formData.get('total_attendees'),
      'address': formData.get('address'),
      'description': formData.get('description'),
      'file_one': formData.get('file_one'),
      'file_two': formData.get('file_two'),
      'event_id': formData.get('event_id'),
      'event_count':eventCountAsNumber,
      'country_state': state,
      'ac_name': vidhanSabha.name,
      'can_create': formData.get('can_create'),
      'photo1': fileOnePath,
      'photo2': fileTwoPath,
      'event_detail_id': eventDetailId,
      'latitude': localStorage.getItem('latitude'),
      'longitude': localStorage.getItem('longitude'),
      'VidhanSabha':vidhanSabha
    };
    navigate('/review', {replace: false, state: {formResult}});
    setloaderEnable(true);
  };

  function setBoothDetail(booth_id, skBoothData) {
    const data = acBooths.concat(skBoothData)
    if (booth_id) {
      data.filter(item => {
        if (parseInt(item.id) === parseInt(booth_id)) {
          setBoothId(item.id);
          setBoothName(item.name);
        }
      });
    }
  }


  function handleBoothList(acBoothData, skBoothsData) {
    if (acBoothData.length && skBoothsData.length) {
    skBoothsData.forEach((skBooth) => {
      acBoothData.forEach((acBooth, acBoothIndex) => {
        if (skBooth.id === acBooth.id) {
          acBoothData.splice(acBoothIndex, 1);
        }
      })
    })
    }
    setOtherBoothInAc(acBoothData);
  }

  function allowedNumericInput(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (!(charCode > 31 && (charCode < 48 || charCode > 57))) {
      setTotalAttendees(event.target.value);
    } else {
      event.preventDefault();
    }
    if (event.target.value.length >= 5) {
      event.preventDefault();
    }
  }

  function getImage(camera) {
    let fileData = dataURLtoFile(camera.current.takePhoto(), 'eventPhoto.jpeg');
    if (fileOnePath === '') {
      setFileOne(fileData);
      setFileOnePath(camera.current.takePhoto())
      handleFileInputChange(fileData);
    } else {
      setFileTwo(fileData);
      setFileTwoPath(camera.current.takePhoto())
      handleFileInputChange(fileData);
    }
    setOpenCamera(false);
  }
  function getFileName() {
    let d = new Date();
    let dformat = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}-${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

    return 'eventPhoto' + localStorage.getItem("userId") + dformat +'.jpeg';
  }

  function dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type: mime});
  }

  function resetImage1() {
    setFileOne('');
    setFileOnePath('');
    setImgUrlOne('');
  }

  function resetImage2() {
    setFileTwoPath('');
    setFileTwo('');
    setImgUrlTwo('');
  }

  const handleChangeCamera = useCallback(() => {
    if (camera.current) {
      camera.current.switchCamera();
    }
  }, [camera]);

  function validateBooth(booth) {
    setBoothError('');
    if (!booth) {
      setBoothError('Booth required')
    }
  }

  function validateTotalAttendees(total) {
    setTotalAttendeesError('');
    if (!total || total[0] === '0') {
      setTotalAttendees('')
      setTotalAttendeesError('Total Attendees required')
    }
  }

  function handleCloseCamera() {
    setOpenCamera(false);
    setTogglefooter(false);
  }
  function handleSk() {
    setSkId('');
    setSkName('');
    setBoothId('');
    setBoothName('');
    setSkBooths([]);
    setOtherBoothInAc([]);
  }

  const handleChangeImage=async (e)=>{
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file)
     console.log('originalFile instanceof Blob', file instanceof Blob); // true
    console.log(`originalFile size ${file.size / 1024 / 1024} MB`);
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
    try {
      const compressedFile = await imageCompression(file, options);
      console.log('compressedFile instanceof Blob', compressedFile instanceof Blob);
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);

       const reader = new FileReader();
    reader.readAsDataURL(compressedFile)
    reader.onload = (ev) => {
      const fileReader = ev.target.result;
      if (fileOnePath === '') {
        setFileOne(file);
        setFileOnePath(fileReader);
        handleFileInputChange(file);
      } else {
        setFileTwo(file);
        setFileTwoPath(fileReader);
        handleFileInputChange(file);
      }
    }
     } catch (error) {
      console.log(error);
    }
  }

  function getGeoLocation() {
    localStorage.setItem('latitude',geolocation.coordinates.lat)
    localStorage.setItem('longitude',geolocation.coordinates.long)
  }

  function handleGeoLocationError() {
    if(!("geolocation" in navigator)){
      return t("Geolocation not supported")
    }
    else {
      return t("Geolocation not found")
    }
  }

  function getLocation() {
    navigator.permissions.query({name:'geolocation'}).then((result) => {
      if (result.state === 'prompt'){
	    	navigator.geolocation.getCurrentPosition(showPosition);
      }
      else if (result.state === 'denied'){
        setgeoLocationErrorFlag(true);
      }
    });
  }
    function showPosition(position) {}

  function handleFileInputChange(file) {
    if (imgUrlOne === '' || imgUrlOne === null) {
      if (!file) return;
      setloaderEnable(true)
      const storageRef = ref(storage, `files/` + getFileName());
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on("state_changed",
          (snapshot) => {},
          (error) => {
            setloaderEnable(false);
            alert(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setImgUrlOne(downloadURL)
              if (downloadURL) {
                setloaderEnable(false)
              }
            });
          }
      );
    } else {
      if (!file) return;
      setloaderEnable(true)
      const storageRef = ref(storage, `files/` + getFileName());
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on("state_changed",
          (snapshot) => {},
          (error) => {
            setloaderEnable(false);
            alert(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setImgUrlTwo(downloadURL)
              if (downloadURL) {
                setloaderEnable(false)
              }
            });
          }
      );
    }
  }

  const handleSetState = (event,value) => {
    if (value === null) {
      setState("");
      setVidhanSabha("");
      setVidhanSabhaList([]);
      setbooth("");
      setBoothList([]);
    } else {
      setState(value);
      setVidhanSabha("");
      setVidhanSabhaList([]);
      setbooth("");
      setBoothList([]);
      getVidhanSabha(value.id);
    }
  };
  const handleSetVidhanSabha = (event,value) => {
    if (value === null) {
      setVidhanSabha("");
      setbooth("");
      setBoothList([]);
    } else {
      setVidhanSabha(value);
      setbooth("");
      setBoothList([]);
      getBooth(value.id);
    }
  };

  const handleSetBooth = (event,value) => {
    if (value === null) {
      setbooth("");
      setBoothId("");
      setBoothName("");
    } else {
      setbooth(value);
      setBoothId(value.id);
      setBoothName(value.name);
    }
  };


  return (
    <div className={openCamera? "camera-container" : "boothDetailContainer"}>
      {geolocation && geolocation.loaded ? getGeoLocation() : <>
        <div className="geo-box">
          <div className="geo">
            <div className="geo-error">{handleGeoLocationError()}</div>
            <button onClick={getLocation} className='geo-button'><img className='geoLocationImg'  src={geolocationimg} alt=""/></button>
          </div>
          {geoLocationErrorFlag? (<>
            <p className="geo-error">{t("Permission denied")}</p>
            <p className="geo-error"> {t("(Go to setting >> Site settings >>")+
                t("Locations >> mannkibaatprogram >> Allow access to location)")}</p>
            <p className="geo-error">{t("OR")}</p>
            <p className="geo-error">{t("(Go to setting >> Privacy and security >> Site settings >>")+
                t("Locations >> mannkibaatprogram >> Allow access to location)")}</p>
          </>) :<> </>}
          </div>
      </>}
      <div className="enterDetailText">
        <p>{t('Enter Details')}</p>
      </div>
      {loaderEnable ? (<> <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={t("Loading..")} color={'#FFFFFF'}
                                  size={100}/> </>) : (<> </>)}
      <div className="formContainerBooth">
        {eventCountAsNumber === 0 && (
            <div className="warningStyle">
              <LocationOnOutlinedIcon className="warningIcon" />
              <div className="warningtext">
                Your current selected state is <strong>“{state.name}”</strong>.<br />
                You can select your State only once for reporting this episode of Mann Ki Baat.It can not be changed later.
              </div>
            </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)} className="form-overflow">

                <Autocomplete
                    className="dropdowns autocomplete"
                    id="state-autocomplete"
                    options={stateList}
                    value={state}
                    getOptionLabel={(option) => option.name || ""}
                    getOptionValue={(option) => option.id || ""}
                    filterOptions={createFilterOptions({
                      stringify: (option) => option.id + option.name
                    })}
                    onChange={handleSetState}
                    disabled={eventCountAsNumber !== 0 && !isNaN(eventCountAsNumber)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={<span>
                          {t('State')}
                                <span style={{ color: '#5A5A61',fontWeight: 600}}>*</span>
                          </span>}
                            InputLabelProps={{
                              style: {
                                display: 'block',
                                fontFamily: 'Quicksand, serif',
                                fontStyle: 'normal',
                                color: '#5A5A61',
                                fontWeight: 600,
                               fontSize: '16px',
                               letterSpacing: '0.02em',
                              }
                            }}
                        />
                    )}
                />

                <Autocomplete
                    className="dropdowns autocomplete"
                    id="vidhan-sabha-autocomplete"
                    options={vidhanSabhaList}
                    value={vidhanSabha}
                    getOptionLabel={(option) => option.name || ""}
                    getOptionValue={(option) => option.id || ""}
                    filterOptions={createFilterOptions({
                      stringify: (option) => option.id + option.name
                    })}
                    onChange={handleSetVidhanSabha}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={
                          <span>
                          {t('Vidhan Sabha')}
                                <span style={{ color: '#5A5A61',fontWeight: 600}}>*</span>
                          </span>}
                            InputLabelProps={{
                              style: {
                                display: 'block',
                                fontFamily: 'Quicksand, serif',
                                fontStyle: 'normal',
                                color: '#5A5A61',
                                fontWeight: 600,
                                fontSize: '16px',
                                letterSpacing: '0.02em',
                              }
                            }}
                        />
                    )}
                />

          <Autocomplete
              className="dropdowns autocomplete"
              id="combo-box-demo"
              options={boothList}
              value={booth}
              getOptionLabel={(option) => option.name || ""}
              getOptionValue = {(option) => option.id || ""}
              renderOption={(props, option) => {
                return (
                    <Grid container{...props}>
                      <Grid item xs={11}>{option.name}</Grid>
                    </Grid>
                );
              }}
              filterOptions={createFilterOptions({
                stringify: (option) => option.id + option.name
              })}
              onChange={
                 handleSetBooth
              }
              renderInput={(params) => <TextField
                  {...params}
                  label={
                    <span>
          {t('Booth')}
                      <span style={{ color: '#5A5A61',fontWeight: 600}}>*</span>
        </span>
                  }
                  InputLabelProps={{
                    style: {
                      display: 'block',
                      fontFamily: 'Quicksand, serif',
                      fontStyle: 'normal',
                      color: '#5A5A61',
                      fontWeight: 600,
                      fontSize: '16px',
                      letterSpacing: '0.02em',
                    },
                  }}
                  // required
                  // error={!booth}
              />}
          />

          <label className='totalAttendeesText mt-3' htmlFor="">{t('Total Attendees')}*</label>
          <input className='custom-input'
                 type="tel"
                 pattern="[0-9]*"
                 maxLength="10"
                 value={totalAttendees}
                 onPaste={(e) => {
                   e.preventDefault();
                   return false;
                 }}
                 onCopy={(e) => {
                   e.preventDefault();
                   return false;
                 }}
                 onChange={(e) => {
                   setTotalAttendees((v) => (e.target.validity.valid ? e.target.value : v))
                   validateTotalAttendees(e.target.value)
                 }}
                 onKeyPress={(event) => allowedNumericInput(event)}
          />
          <small className="error-msg">{t(totalAttendeesError)}</small>
          <label className='addressText mt-3' htmlFor="">{t('Event Address')}</label>
          <textarea
            className='address-custom-input'
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />

          <label className='boothdescriptionText mt-3' htmlFor="">{t('Remarks')}</label>
          <textarea
            className="descriptionBox"
            name="comment"
            form="usrform"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          {fileOnePath === '' || fileTwoPath === '' ? (<>
             <label className='boothdescriptionText mt-3' htmlFor="">{t('Image (Max 2)')}*</label>
            <div className="uploadImgContainer" onClick={ onOpenCameraClickHandler}>
              <label>{t('Capture Image')}</label>
            </div>
            <label className='boothdescriptionText text-center mt-2' htmlFor="">{t('OR')}</label>
            <div className="input-file mt-2">
              <p className="upload-label">{t ('Upload Image')}</p>
              <input type="file" id="img" name="img" accept="image/*" className="opt-0" onChange={handleChangeImage}/>
            </div>

          </>) : (<>
            <div className="d-flex justify-content-around">
              <label className='descriptionText' htmlFor="">{t('Uploaded images')}</label>
            </div>
          </>)}
          <div className="mt-3 d-flex justify-content-between">
            {fileOnePath ? (<>
              <div className="position-relative preview-img-container1">
                <img src={RemoveIcon} alt="Remove Icon" onClick={() => resetImage1()} className="position-absolute img-fluid cursor-pointer text-danger" />
                <img className="captured-preview" src={fileOnePath} alt='Taken photo'/>
              </div>
            </>) : (<></>)}
            {fileTwoPath ? (<>
               <div className="position-relative preview-img-container2">
                <img src={RemoveIcon} alt="Remove Icon" onClick={resetImage2} className="position-absolute cursor-pointer text-danger img-fluid" />
                <img className="captured-preview" src={fileTwoPath} alt='Taken photo'/>
              </div>
            </>) : (<></>)}
          </div>
          <div className="buttonContainer">
            <button type='submit' className='button'>
              {t('Review')}
            </button>
          </div>
        </form>
        {openCamera ? (<>
          <div className="abc">
            <Camera ref={camera}/>
            </div>
            <div className="booth-img-click-btn">
              <button className="camera-action-btn switch-btn" onClick={handleChangeCamera}><small
                className="camera-action-text">{t('Switch')}</small></button>
              <button className="camera-action-btn" onClick={handleOnCaptureClick}><small
                className="camera-action-text">{t('Capture')}</small></button>
              <button className="camera-action-btn" onClick={handleCloseCamera}><small
                className="camera-action-text">{t('Close')}</small></button>
            </div>
          </>) :
          (<> </>)}
      </div>
    </div>
  )
}
